document.addEventListener('DOMContentLoaded', function () {
    new Splide('#imageSliderFullscreen', {
        arrows: 'slider',
        breakpoints: {
            640: {
                pagination: false,
            },
        },
    }).mount();

    new Splide('#cardSlider', {
        perPage: 3,
        perMove: 1,
        focus: 'center',
        arrows: 'slider',
        gap: '3rem',
        padding: { left: '3rem', right: '3rem' },
        breakpoints: {
            640: {
                perPage: 1,
                pagination: false,
            },
        },
    }).mount();
});
